import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from 'components/Layout';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';
import { ScrollToTop } from 'utils/scrollToTop';
import * as URL from './url';

const LandingPage = lazy(() => import('screens/LandingPage/LandingPage'));
const LatestReportsPage = lazy(
  () => import('screens/LatestReportsPage/LatestReportsPage'),
);
const IndustryAveragesPage = lazy(
  () => import('screens/IndustryAveragesPage/IndustryAveragesPage'),
);
const CompanySearchResultPage = lazy(
  () => import('screens/CompanySearchResultPage/CompanySearchResultPage'),
);
const CompanyLandingPage = lazy(
  () => import('screens/CompanyLandingPage/CompanyLandingPage'),
);
const DisclaimerPage = lazy(
  () => import('screens/DisclaimerPage/DisclaimerPage'),
);
const AddEsgReportPage = lazy(
  () => import('screens/AddEsgReportPage/AddEsgReportPage'),
);
const GreetingsFromCEOPage = lazy(
  () => import('screens/GreetingsFromCEOPage/GreetingsFromCEOPage'),
);
const ProductsPage = lazy(() => import('screens/ProductsPage/ProductsPage'));
const LeagueTablesPage = lazy(
  () => import('screens/LeagueTablesPage/LeagueTablesPage'),
);
const LearningCenterPage = lazy(
  () => import('screens/LearningCenterPage/LearningCenterPage'),
);

const InsightsPage = lazy(() => import('screens/InsightsPage/InsightsPage'));
const KeyTrendsInGHGRestatementsPage = lazy(
  () => import('screens/InsightsPage/screens/KeyTrendsInGHGRestatements'),
);
const InsightsNorwayGenderGapPage = lazy(
  () => import('screens/InsightsPage/screens/NorwayGenderGapArticle'),
);
const ESAAnnouncesStricterLinePage = lazy(
  () => import('screens/InsightsPage/screens/ESAAnnouncesStricterLineArticle'),
);
const CapitalMarketESGReportPage = lazy(
  () => import('screens/InsightsPage/screens/CapitalMarketESGReport2024'),
);
const NotAvailablePage = lazy(
  () => import('screens/NotAvailablePage/NotAvailablePage'),
);
const NotFoundPage = lazy(() => import('screens/NotFoundPage/NotFoundPage'));
const UserTermsPage = lazy(() => import('screens/UserTermsPage/UserTermsPage'));

const CompanyLandingPageProvider = lazy(
  () => import('screens/CompanyLandingPage/context/CompanyLandingPageContext'),
);

export const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Route exact path={URL.ROOT}>
            <Suspense fallback={<LoadingScreen />}>
              <LandingPage />
            </Suspense>
          </Route>
          <Route path={URL.LATEST_REPORTS}>
            <Suspense fallback={<LoadingScreen />}>
              <LatestReportsPage />
            </Suspense>
          </Route>
          <Route path={URL.INDUSTRY_AVERAGES}>
            <Suspense fallback={<LoadingScreen />}>
              <IndustryAveragesPage />
            </Suspense>
          </Route>
          <Route path={URL.COMPANY_SEARCH_RESULT}>
            <Suspense fallback={<LoadingScreen />}>
              <CompanySearchResultPage />
            </Suspense>
          </Route>
          <Route path={URL.COMPANY_SECTION}>
            <Suspense fallback={<LoadingScreen />}>
              <CompanyLandingPageProvider>
                <CompanyLandingPage />
              </CompanyLandingPageProvider>
            </Suspense>
          </Route>
          <Route path={URL.DISCLAIMER_PAGE}>
            <Suspense fallback={<LoadingScreen />}>
              <DisclaimerPage />
            </Suspense>
          </Route>
          <Route path={URL.ADD_ESG_REPORT}>
            <Suspense fallback={<LoadingScreen />}>
              <AddEsgReportPage />
            </Suspense>
          </Route>
          <Route path={URL.GREETINGS_FROM_STAMDATA}>
            <Suspense fallback={<LoadingScreen />}>
              <GreetingsFromCEOPage />
            </Suspense>
          </Route>
          <Route path={URL.PRODUCTS}>
            <Suspense fallback={<LoadingScreen />}>
              <ProductsPage />
            </Suspense>
          </Route>
          <Route path={URL.USER_TERMS}>
            <Suspense fallback={<LoadingScreen />}>
              <UserTermsPage />
            </Suspense>
          </Route>
          <Route path={URL.LEAGUE_TABLE_SECTION}>
            <Suspense fallback={<LoadingScreen />}>
              <LeagueTablesPage />
            </Suspense>
          </Route>
          <Route path={URL.NOT_AVAILABLE}>
            <Suspense fallback={<LoadingScreen />}>
              <NotAvailablePage />
            </Suspense>
          </Route>
          <Route path={URL.LEARNING_CENTER_SECTION}>
            <Suspense fallback={<LoadingScreen />}>
              <LearningCenterPage />
            </Suspense>
          </Route>
          <Route exact path={URL.INSIGHTS}>
            <Suspense fallback={<LoadingScreen />}>
              <InsightsPage />
            </Suspense>
          </Route>
          <Route path={URL.INSIGHTS_KEY_TRENDS_IN_GHG_RESTATEMENTS}>
            <Suspense fallback={<LoadingScreen />}>
              <KeyTrendsInGHGRestatementsPage />
            </Suspense>
          </Route>
          <Route path={URL.INSIGHTS_NORWAY_GENDER_GAP}>
            <Suspense fallback={<LoadingScreen />}>
              <InsightsNorwayGenderGapPage />
            </Suspense>
          </Route>
          <Route
            path={URL.INSIGHTS_FINANSTILSYNET_WITH_STRICTER_DATA_REQUIREMENTS}
          >
            <Suspense fallback={<LoadingScreen />}>
              <ESAAnnouncesStricterLinePage />
            </Suspense>
          </Route>
          <Route path={URL.CAPITAL_MARKET_ESG_REPORT_2024}>
            <Suspense fallback={<LoadingScreen />}>
              <CapitalMarketESGReportPage />
            </Suspense>
          </Route>
          <Route path={URL.ACCESS_BLOCKED}>
            <h1>Access Blocked</h1>
          </Route>
          <Route>
            <Suspense fallback={<LoadingScreen />}>
              <NotFoundPage />
            </Suspense>
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};
